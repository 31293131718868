import "../assets/css/style.css";
import BannerEl from "../elements/pageSections/banner/BannerEl";
import Layout from "../common/Layout";
import React from "react";
import SloganSection from "../elements/pageSections/banner/SloganSection";
import DigitalSolCards from "../elements/service/DigitalSolCards";
import ItemListings from "../elements/itemlisting/ItemListings";
import TestSectionTitle from "../elements/sectionTitle/TestSectionTitle";
import SplitNoButton from "../elements/split/SplitNoButton";
import SplitNoButtonSwap from "../elements/split/SplitNoButtonSwap";
import r2fullarchdata from "../data/splitdata/r2fullarch.json";
import NewCTATest from "../elements/calltoaction/NewCTATest";
import R2FullArchCTA from "../elements/calltoaction/R2FullArchCTA";

const BannerData = [
  {
    image: "",
  },
];

const R2FullArch = () => {
  const loaded = "https://placehold.co/2560x1080";

  return (
    <>
      <Layout>
        <BannerEl loaded={loaded} bannerData={BannerData} />
        <div className="main-content mt--30">
          <div className="container">
            <SloganSection
              slogan={"Clinical Education"}
              product={"R2 Full Arch Protocol"}
              description={
                "Minimally invasive, biologically driven approach for full arch rehabilitation. It combines advanced digital planning with precise surgical techniques to ensure optimal restorative and functional outcomes. The protocol focuses on tissue preservation, implant stability, and rapid recovery. It offers a predictable, efficient solution for patients seeking a lasting, functional, and aesthetic full arch restoration."
              }
              alt={"Digital Solutions"}
              secondaryAlt={""}
              visibility={false}
              pdfLink={""}
              image={"https://placehold.co/600x600"}
            />{" "}
            <TestSectionTitle
              classes="text-center mb--40"
              slogan="R2 Full Arch"
              title="Full Protocol"
              description=""
            />
            <SplitNoButton props={r2fullarchdata[0]} />
            <SplitNoButtonSwap props={r2fullarchdata[1]} />
            <SplitNoButton props={r2fullarchdata[2]} />
            <SplitNoButtonSwap props={r2fullarchdata[3]} />
            <SplitNoButton props={r2fullarchdata[4]} />
            <SplitNoButtonSwap props={r2fullarchdata[5]} />
            <SplitNoButton props={r2fullarchdata[6]} />
            <SplitNoButtonSwap props={r2fullarchdata[7]} />
            <R2FullArchCTA />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default R2FullArch;
