import "../assets/css/style.css";
import BannerEl from "../elements/pageSections/banner/BannerEl";
import Layout from "../common/Layout";
import React from "react";
import SloganSection from "../elements/pageSections/banner/SloganSection";
import DigitalSolCards from "../elements/service/DigitalSolCards";
import ItemListings from "../elements/itemlisting/ItemListings";
import TestLayout from "../common/TestLayout";

const listingData = [
  [
    {
      name: "Why MegaGen?",
      img: "https://placehold.co/300x300",
      alt: "Why MegaGen",
      href: "/why-megagen",
    },
    {
      name: "Same Day Implants",
      img: "https://placehold.co/300x300",
      alt: "one day implant",
      href: "/one-day-implants",
    },
    {
      name: "Treatment Benefits",
      img: "https://placehold.co/300x300",
      alt: "treatment benefits",
      href: "/implant-treatment",
    },
    {
      name: "FAQ",
      img: "https://placehold.co/300x300",
      alt: "faq",
      href: "/faq",
    },
  ],
];
const PatientsFAQ = () => {
  return (
    <>
      <TestLayout>
        <ItemListings
          category="Frequently Searched Topics"
          prodClass="data"
          description=""
          data={listingData[0]}
          bgColor={"#"}
        />
      </TestLayout>
    </>
  );
};

export default PatientsFAQ;
