import "../assets/css/style.css";
import BannerEl from "../elements/pageSections/banner/BannerEl";
import Layout from "../common/Layout";
import React from "react";
import SloganSection from "../elements/pageSections/banner/SloganSection";
import DigitalSolCards from "../elements/service/DigitalSolCards";
import ItemListings from "../elements/itemlisting/ItemListings";

const BannerData = [
  {
    image: "",
  },
];

const listingData = [
  [
    {
      name: "R2 Studio Q",
      slogan: "All In One Digital Workstation",
      img: "https://placehold.co/300x300",
      alt: "R2 Studio Q CBCT",
      href: "/products/equipment/r2-studio-q",
    },
    {
      name: "R2i3",
      slogan: "New AI-driven era for intraoral scanners",
      img: "https://placehold.co/300x300",
      alt: "R2i3 IOS",
      href: "/products/equipment/r2i3",
    },
    {
      name: "MEDIT",
      slogan: "Digital Dentistry At Your Fingertips",
      img: "https://placehold.co/300x300",
      alt: "MEDIT IOS",
      href: "/products/equipment/medit",
    },
    {
      name: "Shining3D",
      slogan: "High-accuracy 3D Scanning Solutions",
      img: "https://placehold.co/300x300",
      alt: "Shining3D",
      href: "/shining3d/scanner",
    },
  ],
  [
    {
      name: "R2GATE",
      slogan: "Start Of Comprehensive Dental Care",
      img: "https://placehold.co/300x300",
      alt: "R2 GATE Software",
      href: "/digital/r2gate",
    },
  ],
  [
    {
      name: "AccuFab-CEL",
      slogan: "Higher, Faster, Easier",
      img: "https://placehold.co/300x300",
      alt: "AccuFab-CEL",
      href: "/shining3d/printers",
    },
    {
      name: "AccuFab-L4D/K",
      slogan: "Connecting the Future",
      img: "https://placehold.co/300x300",
      alt: "AccuFab-L4D/K",
      href: "/shining3d/printers",
    },
    {
      name: "FabWash",
      slogan: "Smarter Post-processing",
      img: "https://placehold.co/300x300",
      alt: "Fab Wash",
      href: "/shining3d/fabwash",
    },
    {
      name: "FabCure 2",
      slogan: "Fabulous Curing",
      img: "https://placehold.co/300x300",
      alt: "Fab Cure 2",
      href: "/shining3d/fabcure-2",
    },
  ],
  [
    {
      name: "Guided Surgical Kits",
      slogan: "AR / BD / AO Guided Kits",
      img: "https://placehold.co/300x300",
      alt: "Guided Surgical Kits MegaGen",
      href: "/products/kits/r2-kits",
    },
    {
      name: "R2 Milling Center",
      slogan: "Prosthetic Solutions",
      img: "https://placehold.co/300x300",
      alt: "R2 milling center",
      href: "https://www.r2milling.com/",
    },
  ],
];
const DigitalSolutions = () => {
  const loaded = "https://placehold.co/2560x1080";

  return (
    <>
      <Layout>
        <BannerEl loaded={loaded} bannerData={BannerData} />
        <SloganSection
          slogan={
            "Revolutionizing Dentistry with Comprehensive Digital Solutions"
          }
          product={"Digital Solutions"}
          description={
            "Experience seamless integration from data acquisition to final prosthetic outcomes. Our cutting-edge technology combines precision, efficiency, and advanced AI capabilities to transform your dental practice, delivering exceptional patient care and streamlined workflows."
          }
          alt={"Digital Solutions"}
          secondaryAlt={""}
          visibility={false}
          pdfLink={""}
          image={"https://placehold.co/600x600"}
        />
        <DigitalSolCards />
        {/* add sections for each so would need 4 full sections */}
        {/* make it dynamic component so i can feed it json data and it can auto populate */}
        <ItemListings
          category="Data Acquisition"
          prodClass="data"
          description="Quality Scans for Quality Results."
          data={listingData[0]}
          bgColor={"#"}
        />
        <ItemListings
          prodClass="planning"
          category="Planning & Design"
          description="Planning & Designing Success, Step by Step"
          data={listingData[1]}
          bgColor={"#"}
        />
        <ItemListings
          prodClass="printing"
          category="Printing"
          description="From Digital Design to Tangible Perfection"
          data={listingData[2]}
          bgColor={"#"}
        />
        <ItemListings
          prodClass="surgical"
          category="Surgical Solutions"
          description="The Right Tools for Every Procedure"
          data={listingData[3]}
          bgColor={"#"}
        />
      </Layout>
    </>
  );
};

export default DigitalSolutions;
