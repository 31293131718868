import React, { useState } from "react";
import jsonp from "jsonp";

const Result = () => {
  return (
    <p className="success-message">
      You have successfully subscribed to our mailing list!
    </p>
  );
};
function NewCTATest() {
  const [email, setEmail] = useState("");
  const [Fname, setFName] = useState("");
  const [Lname, setLName] = useState("");

  const [result, showresult] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    showresult(true);
    setTimeout(() => {
      showresult(false);
    }, 5000);
    const url =
      "https://megagenamerica.us1.list-manage.com/subscribe/post?u=9fc3756a2fdc723724e5a2a47&amp;id=db61361f0f&amp;f_id=008efbe5f0";
    jsonp(
      `${url}&EMAIL=${email}&FNAME=${Fname}&LNAME=${Lname}`,
      { param: "c" },
      (_, { msg }) => {
        alert(msg);
      }
    );
  };
  return (
    <>
      <div className={`mgga-signup-area section-gap mb--90`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="mgga-signup-box"
                style={{
                  background: "linear-gradient(to bottom, #059DFF, #E0FFFF)",
                }}
              >
                <span>MegaGen Newsletter</span>
                <h3 className="title">Stay Updated</h3>
                <form onSubmit={onSubmit}>
                  <div className="input-box">
                    <input
                      type="text"
                      placeholder="First Name"
                      onChange={(e) => setFName(e.target.value)}
                    />
                  </div>
                  <div className="input-box">
                    <input
                      type="text"
                      placeholder="Last Name"
                      onChange={(e) => setLName(e.target.value)}
                    />
                  </div>
                  <div className="input-box">
                    <input
                      type="email"
                      placeholder="Enter your email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="input-box">
                    <button type="submit">Sign Up</button>
                  </div>
                  <div className="form-group">{result ? <Result /> : null}</div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewCTATest;
