import { SlClose } from "react-icons/sl";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
const ModalPopUp = ({ show, handleClose, href, img, color }) => {
  return (
    <Modal show={show} onHide={handleClose} centered size="md">
      <div style={{ textAlign: "right" }}>
        <Button
          variant="secondary"
          onClick={handleClose}
          style={{
            fontSize: "25px",
            border: "none",
            background: "none",
            color: color,
            width: "50px",
            zIndex: "1000000",
            position: "absolute",
            right: "4px",
          }}
        >
          <SlClose />
        </Button>
      </div>
      <div
        style={{
          background: "none",
          padding: "0",
          borderRadius: "10px",
        }}
      >
        <a
          style={{ padding: 0, border: "none", borderRadius: "5px" }}
          href={href}
          target="_blank"
          rel="noreferrer"
        >
          <img
            style={{ borderRadius: "5px" }}
            src={img}
            alt="n2 chair promo modal"
          />
        </a>
      </div>
    </Modal>
  );
};

export default ModalPopUp;
