import React, { Suspense, useRef } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Stage } from "@react-three/drei";

import StepRenderDarkBlueCart from "./StepRenderDarkBlueCart";
import StepRenderDarkBlueMount from "./StepRenderDarkBlueMount";
import StepRenderBlackCart from "./StepRenderBlackCart";
import StepRenderBlackMount from "./StepRenderBlackMount";
import StepRenderVioletCart from "./StepRenderVioletCart";
import StepRenderVioletMount from "./StepRenderVioletMount";
import StepRenderBrownCart from "./StepRenderBrownCart";
import StepRenderBrownMount from "./StepRenderBrownMount";
import StepRenderGreenCart from "./StepRenderGreenCart";
import StepRenderGreenMount from "./StepRenderGreenMount";
import StepRenderRedCart from "./StepRenderRedCart";
import StepRenderRedMount from "./StepRenderRedCartMount";
import StepRenderWhiteCart from "./StepRenderWhiteCart";
import StepRenderWhiteMount from "./StepRenderWhiteMount";
import StepRenderIvoryCart from "./StepRenderIvoryCart";
import StepRenderIvoryMount from "./StepRenderIvoryMount";
import StepRenderSkyBlueMount from "./StepRenderSkyBlueMount";
import StepRenderSkyBlueCart from "./StepRenderSkyBlueCart";
import StepRenderBlueCart from "./StepRenderBlueCart";
import StepRenderBlueMount from "./StepRenderBlueMount";
import StepRenderYellowMount from "./StepRenderYellowMount";
import StepRenderYellowCart from "./StepRenderYellowCart";
import StepRenderOrangeCart from "./StepRenderOrangeCart";
import StepRenderOrangeMount from "./StepRenderOrangeMount";
import StepRenderGoldMount from "./StepRenderGoldMount";
import StepRenderGoldCart from "./StepRenderGoldCart";
// const StepRender = lazy(() => import("./StepRenderDarkBlue"));
// const StepRenderDarkBlueMount = lazy(() => import("./StepRenderDarkBlueMount"));

export default function FinalCanvas(props) {
  // I can probably add a bunch of variables here depending on color or type it would
  // change so something like if color was this and type was this use this as step render

  const ref = useRef();

  const project = () => {
    switch (props.type) {
      case "mount":
        switch (props.color) {
          case "darkblue":
            return <StepRenderDarkBlueMount />;
          case "black":
            return <StepRenderBlackMount />;
          case "violet":
            return <StepRenderVioletMount />;
          case "brown":
            return <StepRenderBrownMount />;
          case "green":
            return <StepRenderGreenMount />;
          case "red":
            return <StepRenderRedMount />;
          case "white":
            return <StepRenderWhiteMount />;
          case "ivory":
            return <StepRenderIvoryMount />;
          case "skyblue":
            return <StepRenderSkyBlueMount />;
          case "blue":
            return <StepRenderBlueMount />;
          case "yellow":
            return <StepRenderYellowMount />;
          case "orange":
            return <StepRenderOrangeMount />;
          case "gold":
            return <StepRenderGoldMount />;
        }
      case "cart":
        switch (props.color) {
          case "darkblue":
            return <StepRenderDarkBlueCart />;
          case "black":
            return <StepRenderBlackCart />;
          case "violet":
            return <StepRenderVioletCart />;
          case "brown":
            return <StepRenderBrownCart />;
          case "green":
            return <StepRenderGreenCart />;
          case "red":
            return <StepRenderRedCart />;
          case "white":
            return <StepRenderWhiteCart />;
          case "ivory":
            return <StepRenderIvoryCart />;
          case "skyblue":
            return <StepRenderSkyBlueCart />;
          case "blue":
            return <StepRenderBlueCart />;
          case "yellow":
            return <StepRenderYellowCart />;
          case "orange":
            return <StepRenderOrangeCart />;
          case "gold":
            return <StepRenderGoldCart />;
        }
      default:
        return <StepRenderDarkBlueMount />;
    }
  };

  return (
    <div className="n2-3d col-12 col-lg-12 m--0 p--0">
      <Canvas shadows dpr={[1, 2]} camera={{ fov: 20 }}>
        <Suspense fallback={null}>
          <Stage
            controls={ref}
            preset="rembrandt"
            intensity={1}
            environment="studio"
          >
            <StepRenderDarkBlueMount />
          </Stage>
        </Suspense>
        <OrbitControls
          ref={ref}
          enableZoom={false}
          enableRotate={false}
          autoRotate={true}
        />
      </Canvas>
    </div>
  );
}
