import FinalCanvas2 from "../../steploader/FinalCanvas2";
import N2PromoForm from "../../form/N2PromoForm";
import DemoForm from "../../form/DemoForm";
import { MdArrowForwardIos } from "react-icons/md";
import React, { useState } from "react";
import * as Checkbox from "@radix-ui/react-checkbox";
import { CheckIcon } from "@radix-ui/react-icons";
const DemoHero = ({ className, sendEmail }) => {
  const [checked, setChecked] = useState("implants");
  return (
    <section
      className={`mgga-hero-area pt--100 ${className || ""}`}
      style={{ background: "linear-gradient(to bottom, #007BFF, #E0FFFF)" }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="mgga-hero-content">
              <span
                style={{
                  fontSize: "25px",
                  fontWeight: 800,
                  color: "#C0C0C0",
                }}
              ></span>
              <h1 className="mgga-title" style={{ color: "white" }}>
                Request More Information
              </h1>
              <h4 style={{ color: "white" }}>
                Fill out the form to get connected to our specialists.
              </h4>

              <ul style={{ listStyle: "none", marginTop: 0 }}>
                <li>
                  <input
                    id="implants"
                    type="checkbox"
                    checked={checked === "implants"}
                    onChange={() => setChecked("implants")}
                  />
                  <label
                    htmlFor="implants"
                    id="implant-checkbox"
                    style={{ color: "white" }}
                  >
                    Implants
                  </label>
                </li>
                <li></li>
                <li></li>
                <li></li>
                <li>
                  <input
                    id="equipment"
                    type="checkbox"
                    checked={checked === "equipment"}
                    onChange={() => setChecked("equipment")}
                  />
                  <label
                    id="equipment-checkbox"
                    htmlFor="equipment"
                    style={{ color: "white" }}
                  >
                    Equipment
                  </label>
                </li>
              </ul>

              <DemoForm sendEmail={sendEmail} category={checked} />
            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="mgga-hero-thumb">
              <div
                className="thumb wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              ></div>{" "}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DemoHero;
