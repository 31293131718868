import ScrollAnimation from "react-animate-on-scroll";

const DemoForm = ({ sendEmail, category }) => {
  return (
    <form
      className="rnt-contact-form rwt-dynamic-form row"
      onSubmit={sendEmail}
    >
      <input type="text" name="promotion" value="N2 Chair" hidden />
      <div className="col-lg-6">
        <ScrollAnimation
          animateIn="flipInX"
          animateOut="slideOutDown"
          animateOnce={true}
          delay={0}
        >
          <div className="form-group mr--20">
            <input
              type="text"
              name="firstName"
              placeholder="First Name*"
              required
            />
          </div>
        </ScrollAnimation>
      </div>
      <div className="col-lg-6">
        <ScrollAnimation
          animateIn="flipInX"
          animateOut="slideOutDown"
          animateOnce={true}
          delay={0}
        >
          <div className="form-group  mr--20">
            <input
              type="text"
              name="lastName"
              placeholder=" Last Name*"
              required
            />
          </div>
        </ScrollAnimation>
      </div>
      <div className="col-lg-6">
        <ScrollAnimation
          animateIn="flipInX"
          animateOut="slideOutDown"
          animateOnce={true}
          delay={0}
        >
          <div className="form-group mr--20">
            <input
              type="text"
              name="practice"
              placeholder="Practice Name*"
              required
            />
          </div>
        </ScrollAnimation>
      </div>
      <div className="col-lg-6">
        <ScrollAnimation
          animateIn="flipInX"
          animateOut="slideOutDown"
          animateOnce={true}
          delay={0}
        >
          <div className="form-group mr--20">
            <input type="email" name="email" placeholder="Email*" required />
          </div>
        </ScrollAnimation>
      </div>
      <div className="col-lg-6">
        <ScrollAnimation
          animateIn="flipInX"
          animateOut="slideOutDown"
          animateOnce={true}
          delay={0}
        >
          <div className="form-group  mr--20">
            <input
              type="text"
              name="phone"
              placeholder="Phone Number*"
              required
            />
          </div>
        </ScrollAnimation>
      </div>
      <div className="col-lg-6">
        <ScrollAnimation
          animateIn="flipInX"
          animateOut="slideOutDown"
          animateOnce={true}
          delay={0}
        >
          <div className="form-group  mr--20">
            <input
              type="text"
              name="zipcode"
              placeholder="ZIP Code*"
              required
            />
          </div>
        </ScrollAnimation>
      </div>
      <div className="col-lg-12">
        <ScrollAnimation
          animateIn="flipInX"
          animateOut="slideOutDown"
          animateOnce={true}
          delay={0}
        >
          {/* If checked is implant or equipment this selection should change */}
          {category === "implants" ? (
            <div className="form-group  mr--20">
              <select name="checkbox" id="my-select" required>
                <option value="" disabled selected>
                  Select An Implant System*
                </option>
                <option value="AnyRidge">AnyRidge</option>
                <option value="BLUEDIAMOND">BLUEDIAMOND</option>
                <option value="ARi">ARi</option>
                <option value="BD Cuff">BD Cuff</option>
              </select>
            </div>
          ) : (
            ""
          )}
          {category === "equipment" ? (
            <div className="form-group  mr--20">
              <select name="checkbox" id="my-select" required>
                <option value="" disabled selected>
                  Select An Equipment*
                </option>
                <option value="N2 Chair">N2 Chair</option>
                <option value="R2 Studio Q">R2 Studio Q</option>
                <option value="R2i3">R2i3</option>
                <option value="Aoralscan Elite">Aoralscan Elite</option>
                <option value="MEDIT">MEDIT</option>
                <option value="MEDIT">3D Printer</option>
              </select>
            </div>
          ) : (
            ""
          )}
        </ScrollAnimation>
      </div>

      <div className="form-group ml--5 mr--20">
        <button
          className="btn-default btn-med"
          style={{ background: "#e23e96" }}
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default DemoForm;
