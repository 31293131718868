import React from "react";

function R2FullArchCTA() {
  return (
    <>
      <div className={`mgga-signup-area`} style={{ marginBottom: "250px" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="mgga-signup-box"
                style={{
                  background: "linear-gradient(to bottom, #059DFF, #E0FFFF)",
                }}
              >
                <span>Want to master the R2 Full Arch Protocol?</span>
                <h3 className="title">
                  Register for our course and gain the skills to deliver
                  exceptional, biologically-driven results.
                </h3>
                <a href="https://www.minecamerica.com/">
                  <button className="mgga-home-cta-btn">Register Now</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default R2FullArchCTA;
