import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PageScrollTop from "./component/pageToTop/PageScrollTop";
import { IoIosCall } from "react-icons/io";
import "bootstrap/dist/css/bootstrap.min.css";
// import "./index.css";
import "react-vertical-timeline-component/style.min.css";
// import CBCTNewsLetterTest from "./pages/CBCTNewsletterTest";
import SinusKitsTest from "./pages/SinusKitTest";
import N2NewsLetterTest from "./pages/N2NewsletterTest";
import ImmediateMolarTest from "./pages/ImmediateMolarTest";
import ComingSoon from "./pages/ComingSoon";
import DigitalSolutions from "./pages/DigitalSol";
import R2FullArch from "./pages/R2FullArch";
import DemoPage from "./pages/DemoPage";
import PatientsFAQ from "./pages/PatientsFAQ";
import "./assets/scss/style.scss";
const HomeDefault = lazy(() => import("./pages/HomeDefault"));
const About = lazy(() => import("./pages/AboutUs"));
const LocalSalesRep = lazy(() => import("./pages/LocalSalesRep"));
const ImplantSystems = lazy(() => import("./pages/ImplantSystems"));
const AnyRidge = lazy(() => import("./pages/AnyRidge"));
const AHA = lazy(() => import("./pages/AHA"));
const AnyOne = lazy(() => import("./pages/AnyOne"));
const Error = lazy(() => import("./pages/Error"));
const BlueDiamond = lazy(() => import("./pages/Bluediamond"));
const Contact = lazy(() => import("./pages/Contact"));
const AdvancedIntermezzo = lazy(() => import("./pages/AdvancedIntermezzo"));
const Kits = lazy(() => import("./pages/Kits"));
const ARSurgicalKit = lazy(() => import("./pages/ARSurgicalKit"));
const BDSurgicalKit = lazy(() => import("./pages/BDSurgicalKit"));
const AOSurgicalKit = lazy(() => import("./pages/AOSurgicalKit"));
const Equipment = lazy(() => import("./pages/Equipment"));
const BoneProfiler = lazy(() => import("./pages/BoneProfiler"));
const ProstheticKit = lazy(() => import("./pages/ProstheticKit"));
const AdvancedIntermezzoKit = lazy(() => import("./pages/AIKit"));
const BonExKit = lazy(() => import("./pages/BonEx"));
const PETKit = lazy(() => import("./pages/PETKit"));
const Kit911 = lazy(() => import("./pages/911Kit"));
const SinusKits = lazy(() => import("./pages/SinusKits"));
const R2Kits = lazy(() => import("./pages/R2Kits"));
const MegaISQ = lazy(() => import("./pages/MegaISQ"));
const MegTorQ = lazy(() => import("./pages/MegTorQ"));
const MegEngine = lazy(() => import("./pages/MegEngine"));
const Medit = lazy(() => import("./pages/Medit"));
const R2Gate = lazy(() => import("./pages/R2GATE"));
const R2DOD = lazy(() => import("./pages/R2DOD"));
const Facegide = lazy(() => import("./pages/FACEGIDE"));
const DigitalProsth = lazy(() => import("./pages/DigitalProsthetics"));
const DigitalEquipment = lazy(() => import("./pages/DigitalEquipment"));
const CBCT = lazy(() => import("./pages/CBCT"));
const PlasmaX = lazy(() => import("./pages/PlasmaX"));
const BlogListSidebar = lazy(() => import("./component/blog/BlogListSidebar"));
const Downloads = lazy(() => import("./pages/Downloads"));
const Manufacturing = lazy(() => import("./pages/Manufacturing"));
const PatientFAQ = lazy(() => import("./pages/PatientFAQ"));
const WhyMegaGen = lazy(() => import("./pages/WhyMegaGen"));
const ImplantTreatment = lazy(() => import("./pages/ImplantTreatment"));
const ImplantBenefits = lazy(() => import("./pages/ImplantBenefits"));
const OneDayImplant = lazy(() => import("./pages/OneDayImplant"));
const Minec = lazy(() => import("./pages/MINEC"));
const N2Chair = lazy(() => import("./pages/N2Chair"));
const IGen = lazy(() => import("./pages/IGenMembrane"));
const Careers = lazy(() => import("./pages/Careers"));
const RMKit = lazy(() => import("./pages/RMKit"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const MegaGenBot = lazy(() => import("./elements/chatbot/ChatBot"));
const MegGyverKit = lazy(() => import("./pages/MegGyverKit"));
const ToC = lazy(() => import("./pages/ToC"));
const ARi = lazy(() => import("./pages/AnyRidgeIncisor"));
const ARiSurgicalKit = lazy(() => import("./pages/ARiSurgicalKit"));
const N2ChairNewsletter = lazy(() => import("./pages/N2ChairNewsletter"));
const CBCTNewsLetter = lazy(() => import("./pages/CBCTNewsletter"));
const PromoConfirm = lazy(() => import("./pages/PromoConfirm"));
const ImmediateLoading = lazy(() => import("./pages/ImmediateMolarLanding"));
const LunchAndLearnLanding = lazy(() => import("./pages/LunchAndLearnLanding"));
const R2i3 = lazy(() => import("./pages/R2i3"));
const TestPage = lazy(() => import("./pages/TestPage"));
const R2i3Promo = lazy(() => import("./pages/R2i3Newsletter"));
const BlackFriday = lazy(() => import("./pages/BlackFriday"));
const CyberMonday = lazy(() => import("./pages/CyberMonday"));
const ShiningScanner = lazy(() => import("./pages/ShiningScanner"));
const ShiningPrinters = lazy(() => import("./pages/ShiningPrinters"));
const ShiningWash = lazy(() => import("./pages/ShiningWash"));
const ShiningCure = lazy(() => import("./pages/ShiningCure"));
const AcceleratedLoading = lazy(() => import("./pages/AcceleratedLoading"));
const SalesTest = lazy(() => import("./pages/SalesRepTest"));
const BDCuff = lazy(() => import("./pages/BDCuff"));
const DownloadsTest = lazy(() => import("./pages/DownloadTest"));
// Import Css Here

const App = () => {
  return (
    <Router>
      <PageScrollTop>
        <Suspense>
          <Switch>
            <Route path={"/"} exact component={HomeDefault} />
            <Route path={"/patients"} exact component={PatientsFAQ} />
            <Route path={"/sinustest"} exact component={SinusKitsTest} />
            {/* <Route path={"/salestest"} exact component={SalesTest} /> */}
            <Route path={"/about-us"} exact component={About} />
            <Route path={"/salesrep"} exact component={SalesTest} />
            <Route path={"/testingPage"} exact component={TestPage} />
            <Route
              path={"/products/implant-systems/bd-cuff"}
              exact
              component={BDCuff}
            />
            <Route
              path={"/products/implant-systems"}
              exact
              component={ImplantSystems}
            />
            <Route
              path={"/immediate-molar"}
              exact
              component={ImmediateLoading}
            />
            <Route
              path={"/accelerated-loading"}
              exact
              component={AcceleratedLoading}
            />
            <Route
              path={"/lunch-&-learn"}
              exact
              component={LunchAndLearnLanding}
            />
            <Route
              path={"/products/implant-systems/anyridge"}
              exact
              component={AnyRidge}
            />
            <Route
              path={"/products/implant-systems/anyridge-incisor"}
              exact
              component={ARi}
            />
            <Route
              path={"/products/implant-systems/bluediamond"}
              exact
              component={BlueDiamond}
            />
            <Route
              path={"/products/implant-systems/anyone"}
              exact
              component={AnyOne}
            />
            <Route
              path={"/products/implant-systems/advanced-intermezzo"}
              exact
              component={AdvancedIntermezzo}
            />
            <Route
              path={"/products/prosthetics/anatomic-healing-abutment"}
              exact
              component={AHA}
            />
            <Route path={"/products/kits"} exact component={Kits} />
            <Route
              path={"/products/kits/ar-surgical-kit"}
              exact
              component={ARSurgicalKit}
            />
            <Route
              path={"/products/kits/ari-surgical-kit"}
              exact
              component={ARiSurgicalKit}
            />
            <Route
              path={"/products/kits/bd-surgical-kit"}
              exact
              component={BDSurgicalKit}
            />
            <Route
              path={"/products/kits/ao-surgical-kit"}
              exact
              component={AOSurgicalKit}
            />
            <Route
              path={"/products/kits/bp-kits"}
              exact
              component={BoneProfiler}
            />
            <Route
              path={"/products/kits/prosthetic-kits"}
              exact
              component={ProstheticKit}
            />
            <Route
              path={"/products/kits/ai-kit"}
              exact
              component={AdvancedIntermezzoKit}
            />
            <Route
              path={"/products/kits/bonex-kit"}
              exact
              component={BonExKit}
            />
            <Route path={"/products/kits/pet-kit"} exact component={PETKit} />
            <Route path={"/products/kits/911-kit"} exact component={Kit911} />
            <Route
              path={"/products/kits/sinus-kits"}
              exact
              component={SinusKits}
            />
            <Route path={"/products/kits/r2-kits"} exact component={R2Kits} />
            <Route path={"/products/kits/rm-kit"} exact component={RMKit} />
            <Route path={"/products/equipment"} exact component={Equipment} />
            <Route
              path={"/products/equipment/mega-isq-ii"}
              exact
              component={MegaISQ}
            />
            <Route
              path={"/products/equipment/meg-torq"}
              exact
              component={MegTorQ}
            />
            <Route
              path={"/products/equipment/meg-engine"}
              exact
              component={MegEngine}
            />
            {/* <Route path={"/products/equipment/r2i3"} exact component={R2i3} /> */}
            <Route
              path={"/products/equipment/n2-unit-chair"}
              exact
              component={N2Chair}
            />
            <Route
              path={"/products/equipment/r2-studio-q"}
              exact
              component={CBCT}
            />
            <Route path={"/products/equipment/medit"} exact component={Medit} />
            <Route path={"/digital/r2gate"} exact component={R2Gate} />
            <Route path={"/digital/r2dod"} exact component={R2DOD} />
            <Route path={"/digital/facegide"} exact component={Facegide} />
            <Route
              path={"/digital/prosthetics"}
              exact
              component={DigitalProsth}
            />
            <Route
              path={"/digital/equipment"}
              exact
              component={DigitalEquipment}
            />
            <Route
              path={"/products/regenerative/igen"}
              exact
              component={IGen}
            />
            <Route path={"/contact"} exact component={Contact} />
            {/* <Route path={"/history"} exact component={History} /> */}
            <Route path={"/safety"} exact component={BlogListSidebar} />
            <Route
              path={"/products/equipment/plasmax-motion"}
              exact
              component={PlasmaX}
            />
            <Route path={"/downloads"} exact component={DownloadsTest} />
            <Route path={"/downloadtest"} exact component={Downloads} />
            <Route path={"/manufacturing"} exact component={Manufacturing} />
            <Route path={"/faq"} exact component={PatientFAQ} />
            <Route path={"/why-megagen"} exact component={WhyMegaGen} />
            <Route
              path={"/implant-treatment"}
              exact
              component={ImplantTreatment}
            />
            <Route
              path={"/treatment-benefits"}
              exact
              component={ImplantBenefits}
            />
            <Route
              path={"/products/kits/meggyver-kit"}
              exact
              component={MegGyverKit}
            />
            <Route path={"/terms"} exact component={ToC} />
            <Route path={"/demo"} exact component={DemoPage} />
            <Route path={"/one-day-implants"} exact component={OneDayImplant} />
            <Route path={"/minec"} exact component={Minec} />
            <Route path={"/careers"} exact component={Careers} />
            <Route
              path={"/promo-n2-chair"}
              exact
              component={N2ChairNewsletter}
            />
            <Route
              path={"/promo-r2-studio-q"}
              exact
              component={CBCTNewsLetter}
            />{" "}
            {/* <Route path={"/black-friday"} exact component={BlackFriday} />{" "}
            <Route path={"/cyber-monday"} exact component={CyberMonday} />{" "} */}
            <Route
              path={"/products/equipment/promo-r2i3"}
              exact
              component={R2i3Promo}
            />
            <Route path={"/promo-r2-studio-q/test"} exact component={R2i3} />{" "}
            <Route
              path={"/promo-n2-chair/test"}
              exact
              component={N2NewsLetterTest}
            />
            <Route
              path={"/immediate-molar/test"}
              exact
              component={ImmediateMolarTest}
            />
            <Route
              path={"/promo-n2-chair/confirmation"}
              exact
              component={PromoConfirm}
            />
            <Route
              path={"/promo-r2-studio-q/confirmation"}
              exact
              component={PromoConfirm}
            />
            <Route path={"/privacy-policy"} exact component={PrivacyPolicy} />
            <Route path={"/products/equipment/r2i3"} exact component={R2i3} />
            <Route
              path={"/digital-solutions"}
              exact
              component={DigitalSolutions}
            />
            <Route path={"/r2-full-arch"} exact component={R2FullArch} />
            <Route
              path={"/shining3d/scanner"}
              exact
              component={ShiningScanner}
            />{" "}
            <Route
              path={"/shining3d/printers"}
              exact
              component={ShiningPrinters}
            />
            <Route path={"/shining3d/fabwash"} exact component={ShiningWash} />
            <Route
              path={"/shining3d/fabcure-2"}
              exact
              component={ShiningCure}
            />
            <Route path={"*"} exact component={Error} />
          </Switch>
        </Suspense>
        <div
          style={{
            position: "fixed",
            top: "400px",
            right: "90px",
            zIndex: "999999",
          }}
        >
          <MegaGenBot />
        </div>{" "}
        <div
          style={{
            position: "fixed",
            top: "680px",
            right: "30px",
            zIndex: "999999",
          }}
        >
          <a
            className="call-btn rsc-float-button"
            aria-label="megagen america call phone number"
            href="tel:+8442885425"
          >
            <IoIosCall />
          </a>
        </div>{" "}
      </PageScrollTop>
    </Router>
  );
};

export default App;
