import React from "react";
import { FiArrowRight } from "react-icons/fi";

function CTAN2Promo({ className }) {
  return (
    <>
      <section className={`mgga-project-area pb-100 mb--50 ${className || ""}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="mgga-project-box wow animated slideInUp"
                style={{
                  background: "linear-gradient(to bottom, #007BFF, #E0FFFF)",
                }}
                data-wow-duration="1000ms"
                data-wow-delay="0ms"
              >
                <div className="row align-items-center">
                  <div className="col-lg-8">
                    <div className="mgga-project-content">
                      <h3 className="title">
                        Want to learn more about the N2 Unit Chair?
                      </h3>
                      <p
                        style={{
                          color: "white",
                          fontWeight: 600,
                          fontSize: "22px",
                        }}
                      >
                        Download our brochure here!
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div
                      className="button-group"
                      style={{ textAlign: "center" }}
                    >
                      {" "}
                      <a
                        className="btn-default btn-medium  btn-icon"
                        href="/pdf/equipment/brochure/n2_brochure.pdf"
                        target="_blank"
                        rel="noreferrer"
                        download
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          background: "#E23E96",
                        }}
                      >
                        Download
                        <i className="icon">
                          <FiArrowRight />
                        </i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CTAN2Promo;
