import ScrollAnimation from "react-animate-on-scroll";

const N2PromoForm = ({ sendEmail }) => {
  return (
    <form
      className="rnt-contact-form rwt-dynamic-form row"
      onSubmit={sendEmail}
    >
      <input type="text" name="promotion" value="N2 Chair" hidden />
      <div className="col-lg-6">
        <ScrollAnimation
          animateIn="flipInX"
          animateOut="slideOutDown"
          animateOnce={true}
          delay={0}
        >
          <div className="form-group mr--20">
            <input
              type="text"
              name="firstName"
              placeholder="First Name*"
              required
            />
          </div>
        </ScrollAnimation>
      </div>
      <div className="col-lg-6">
        <ScrollAnimation
          animateIn="flipInX"
          animateOut="slideOutDown"
          animateOnce={true}
          delay={0}
        >
          <div className="form-group  mr--20">
            <input
              type="text"
              name="lastName"
              placeholder=" Last Name*"
              required
            />
          </div>
        </ScrollAnimation>
      </div>
      <div className="col-lg-6">
        <ScrollAnimation
          animateIn="flipInX"
          animateOut="slideOutDown"
          animateOnce={true}
          delay={0}
        >
          <div className="form-group mr--20">
            <input
              type="text"
              name="practice"
              placeholder="Practice Name*"
              required
            />
          </div>
        </ScrollAnimation>
      </div>
      <div className="col-lg-6">
        <ScrollAnimation
          animateIn="flipInX"
          animateOut="slideOutDown"
          animateOnce={true}
          delay={0}
        >
          <div className="form-group mr--20">
            <input type="email" name="email" placeholder="Email*" required />
          </div>
        </ScrollAnimation>
      </div>
      <div className="col-lg-6">
        <ScrollAnimation
          animateIn="flipInX"
          animateOut="slideOutDown"
          animateOnce={true}
          delay={0}
        >
          <div className="form-group  mr--20">
            <input
              type="text"
              name="phone"
              placeholder="Phone Number*"
              required
            />
          </div>
        </ScrollAnimation>
      </div>
      <div className="col-lg-6">
        <ScrollAnimation
          animateIn="flipInX"
          animateOut="slideOutDown"
          animateOnce={true}
          delay={0}
        >
          <div className="form-group  mr--20">
            <input
              type="text"
              name="zipcode"
              placeholder="ZIP Code*"
              required
            />
          </div>
        </ScrollAnimation>
      </div>
      <div className="col-lg-6">
        <ScrollAnimation
          animateIn="flipInX"
          animateOut="slideOutDown"
          animateOnce={true}
          delay={0}
        >
          <div className="form-group  mr--20">
            <select name="checkbox" id="my-select" required>
              <option value="">Where did you hear about us?*</option>
              <option value="Instagram">Instagram</option>
              <option value="Facebook">Facebook</option>
              <option value="Post Card">Post Card</option>
              <option value="Email Blast">Email</option>
            </select>
          </div>
        </ScrollAnimation>
      </div>
      <div className="col-lg-6">
        <ScrollAnimation
          animateIn="flipInX"
          animateOut="slideOutDown"
          animateOnce={true}
          delay={0}
        >
          <div className="form-group  mr--20">
            <input
              type="text"
              name="quantity"
              placeholder="Quantity*"
              required
            />
          </div>
        </ScrollAnimation>
      </div>
      <div className="form-group ml--5 mr--20">
        <button className="btn-default btn-med">Get Offer</button>
      </div>
    </form>
  );
};

export default N2PromoForm;
