import React from "react";

function MorePromosN2() {
  return (
    <>
      <section className="mgga-about-8-area pt--100 pb--100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="mgga-section-title mb-30">
                <h3 className="mgga-title">
                  Check out <br />
                  our other promotions.
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div
                className="mgga-about-8-box pt--30 pb--150"
                style={{ background: "url('/video/studioq.webp')" }}
              >
                <h3
                  className="title mb--0"
                  style={{ color: "white", fontWeight: 900 }}
                >
                  R2 Studio Q
                </h3>
                <p style={{ color: "white", fontWeight: 700 }}>
                  Precise Solutions With Integrated <br />
                  <span style={{ color: "#87CEFA", fontWeight: 900 }}>
                    Vertical Dimension Protocol
                  </span>
                  <br />
                  Your{" "}
                  <span style={{ color: "#87CEFA", fontWeight: 900 }}>
                    ALL IN ONE
                  </span>{" "}
                  Dental Imaging Solution.
                </p>{" "}
                <div style={{ textAlign: "right" }}>
                  <a className="main-btn" href="/promo-r2-studio-q">
                    Learn More
                  </a>
                </div>
                <div className="thumb">
                  <img alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="mgga-about-8-box pt--30 pb--150"
                style={{ background: "url('/video/molar.webp')" }}
              >
                <h3
                  className="title mb--0 "
                  style={{ color: "white", fontWeight: 900 }}
                >
                  Immediate Molar Predictable!
                </h3>
                <p style={{ color: "#7DF9FF", fontWeight: 700 }}>
                  Making it easier! Solution Based Implantology.
                </p>
                <br />
                <br />
                <div style={{ textAlign: "right" }}>
                  <a className="main-btn" href="/immediate-molar">
                    Learn More
                  </a>
                </div>
                <div className="thumb mr-30">
                  <img alt="" />
                </div>
              </div>
            </div>{" "}
            <div className="col-lg-6 mt--10">
              <div
                className="mgga-about-8-box pt--30 pb--165"
                style={{
                  background:
                    "url('/images/img/promo/r2i3/R2i3_Postcard.webp')",
                }}
              >
                <h3
                  className="title mb--0 "
                  style={{ color: "white", fontWeight: 900 }}
                >
                  R2i3 IOS
                </h3>
                <p
                  className="mb--0"
                  style={{ color: "#7DF9FF", fontWeight: 700 }}
                >
                  New AI-driven era <br />
                  For Intraoral Scanners
                </p>
                <br />
                <div style={{ textAlign: "right" }}>
                  <a className="main-btn" href="/products/equipment/promo-r2i3">
                    Learn More
                  </a>
                </div>
                <div className="thumb mr-30">
                  <img alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MorePromosN2;
