import React, { useState, useEffect, useRef } from "react";
import throttle from "lodash.throttle"; // Import throttle from lodash

function FeatureNewsletterN2({ className }) {
  const [tab, setTab] = useState("report");
  const sectionRef = useRef(null);
  const lastScroll = useRef(0);
  const currentTabIndex = useRef(0);
  const tabs = ["report", "notice", "setting", "app", "nothing"];
  const isScrolling = useRef(false);

  const handleClick = (e, value) => {
    e.preventDefault();
    setTab(value);
    currentTabIndex.current = tabs.indexOf(value);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // Start observing scroll direction
          window.addEventListener("scroll", throttledHandleScroll);
        } else {
          // Stop observing scroll direction
          window.removeEventListener("scroll", throttledHandleScroll);
        }
      },
      { threshold: 0.5 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
      window.removeEventListener("scroll", throttledHandleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (isScrolling.current) return;

    const currentScroll = window.pageYOffset;
    if (currentScroll > lastScroll.current) {
      navigateTabs("down");
    } else {
      navigateTabs("up");
    }
    lastScroll.current = currentScroll;
  };

  const throttledHandleScroll = throttle(handleScroll, 200);

  const navigateTabs = (direction) => {
    if (direction === "down" && currentTabIndex.current < tabs.length - 1) {
      currentTabIndex.current += 1;
    } else if (direction === "up" && currentTabIndex.current > 0) {
      currentTabIndex.current -= 1;
    }
    setTab(tabs[currentTabIndex.current]);
    isScrolling.current = true;
    setTimeout(() => {
      isScrolling.current = false;
    }, 600); // Add a delay of 500ms between tab changes
  };
  return (
    <section
      className={`mgga-features-area  ${className} pt--140 pb--100`}
      id="features"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="mgga-features-tabs-btn">
              <div
                className="nav flex-column nav-pills"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <a
                  onClick={(e) => handleClick(e, "report")}
                  className={`nav-link ${tab === "report" ? "active" : ""}`}
                  id="v-pills-profile-tab"
                  data-toggle="pill"
                  href="#v-pills-profile"
                  role="tab"
                  aria-controls="v-pills-profile"
                  aria-selected="false"
                  style={{ fontSize: "20px" }}
                >
                  Cart Type
                </a>
                <a
                  onClick={(e) => handleClick(e, "notice")}
                  className={`nav-link ${tab === "notice" ? "active" : ""}`}
                  id="v-pills-messages-tab"
                  data-toggle="pill"
                  href="#v-pills-messages"
                  role="tab"
                  aria-controls="v-pills-messages"
                  aria-selected="false"
                  style={{ fontSize: "20px" }}
                >
                  Mount Type
                </a>
                <a
                  onClick={(e) => handleClick(e, "setting")}
                  className={`nav-link ${tab === "setting" ? "active" : ""}`}
                  id="v-pills-home-tab"
                  data-toggle="pill"
                  href="#v-pills-home"
                  role="tab"
                  aria-controls="v-pills-home"
                  aria-selected="true"
                  style={{ fontSize: "20px" }}
                >
                  Design
                </a>
                <a
                  onClick={(e) => handleClick(e, "app")}
                  className={`nav-link ${tab === "app" ? "active" : ""}`}
                  id="v-pills-settings-tab"
                  data-toggle="pill"
                  href="#v-pills-settings"
                  role="tab"
                  aria-controls="v-pills-settings"
                  aria-selected="false"
                  style={{ fontSize: "20px" }}
                >
                  Color Options
                </a>
                <a
                  onClick={(e) => handleClick(e, "nothing")}
                  className={`nav-link ${tab === "nothing" ? "active" : ""}`}
                  id="v-pills-home-tab"
                  data-toggle="pill"
                  href="#v-pills-home"
                  role="tab"
                  aria-controls="v-pills-home"
                  aria-selected="true"
                  style={{ fontSize: "20px" }}
                >
                  Dual Light
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-9">
            <div
              className="tab-content"
              id="v-pills-tabContent"
              ref={sectionRef}
            >
              <div
                className={`${
                  tab === "report" ? "show active" : ""
                } tab-pane fade`}
                id="v-pills-profile"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
              >
                <div className="row align-items-center">
                  <div className="col-lg-6" style={{ height: "440px" }}>
                    <div
                      className="mgga-features-thumb text-center animated fadeInUp"
                      data-wow-duration="2000ms"
                      data-wow-delay="200ms"
                    >
                      <img
                        src="/video/n2-1.webp"
                        style={{ height: "300px" }}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-6" style={{ height: "440px" }}>
                    <div
                      className="mgga-features-content animated fadeInRight"
                      data-wow-duration="2000ms"
                      data-wow-delay="600ms"
                    >
                      <span
                        style={{
                          fontSize: "25px",
                          fontWeight: 800,
                        }}
                      >
                        Features
                      </span>
                      <h3 className="title" style={{ fontSize: "40px" }}>
                        Type Selection
                      </h3>
                      <p style={{ fontSize: "20px" }}>
                        • Doctor Table Separate From Chair <br />• Monitor Arm
                        Attachment By The Foot Of Chair <br />• 32" Monitor Arm
                        <br />• More Mobility
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  tab === "notice" ? "show active" : ""
                } tab-pane fade`}
                id="v-pills-messages"
                role="tabpanel"
                aria-labelledby="v-pills-messages-tab"
              >
                <div className="row align-items-center">
                  <div className="col-lg-6" style={{ height: "440px" }}>
                    <div
                      className="mgga-features-thumb text-center animated fadeInUp"
                      data-wow-duration="2000ms"
                      data-wow-delay="200ms"
                    >
                      <img
                        src="/video/n2-2.webp"
                        style={{ height: "300px" }}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-6" style={{ height: "440px" }}>
                    <div
                      className="mgga-features-content animated fadeInRight"
                      data-wow-duration="2000ms"
                      data-wow-delay="600ms"
                    >
                      <span
                        style={{
                          fontSize: "25px",
                          fontWeight: 800,
                        }}
                      >
                        Features
                      </span>
                      <h3 className="title" style={{ fontSize: "40px" }}>
                        Type Selection
                      </h3>
                      <p style={{ fontSize: "20px" }}>
                        • Doctor Table Mounted To Chair <br />• Monitor Arm
                        Attachment On Side <br />• 24" Monitor Arm
                        <br />• Optimize Space
                      </p>
                    </div>
                  </div>
                </div>
              </div>{" "}
              <div
                className={`${
                  tab === "setting" ? "show active" : ""
                } tab-pane fade`}
                id="v-pills-home"
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
              >
                <div className="row align-items-center">
                  <div className="col-lg-6" style={{ height: "440px" }}>
                    <div
                      className="mgga-features-thumb text-center wow animated fadeInUp"
                      data-wow-duration="2000ms"
                      data-wow-delay="200ms"
                    >
                      <img
                        src="/video/n2-3.webp"
                        style={{ height: "300px" }}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-6" style={{ height: "440px" }}>
                    <div
                      className="mgga-features-content wow animated fadeInRight"
                      data-wow-duration="2000ms"
                      data-wow-delay="600ms"
                    >
                      <span
                        style={{
                          fontSize: "25px",
                          fontWeight: 800,
                        }}
                      >
                        Features
                      </span>
                      <h3 className="title" style={{ fontSize: "40px" }}>
                        Design
                      </h3>
                      <p style={{ fontSize: "20px" }}>
                        • Hydraulic Chair
                        <br />• Smooth Reclination
                        <br />• Full Body Support
                        <br />• Streamlined Design
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  tab === "app" ? "show active" : ""
                } tab-pane fade`}
                id="v-pills-settings"
                role="tabpanel"
                aria-labelledby="v-pills-settings-tab"
              >
                <div className="row align-items-center">
                  <div className="col-lg-6" style={{ height: "440px" }}>
                    <div
                      className="mgga-features-thumb text-center animated fadeInUp"
                      data-wow-duration="2000ms"
                      data-wow-delay="200ms"
                    >
                      <img
                        src="/video/n2-4.webp"
                        style={{ height: "300px" }}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-6" style={{ height: "440px" }}>
                    <div
                      className="mgga-features-content animated fadeInRight"
                      data-wow-duration="2000ms"
                      data-wow-delay="600ms"
                    >
                      <span
                        style={{
                          fontSize: "25px",
                          fontWeight: 800,
                        }}
                      >
                        Features
                      </span>
                      <h3 className="title">Color Options</h3>
                      <p style={{ fontSize: "20px" }}>
                        Immerse your dental practice in a world of aesthetic
                        possibilities.
                        <br />
                        <br />• 13 Color Options To Fit Your Office Environment
                      </p>
                    </div>
                  </div>
                </div>
              </div>{" "}
              <div
                className={`${
                  tab === "nothing" ? "show active" : ""
                } tab-pane fade`}
                id="v-pills-settings"
                role="tabpanel"
                aria-labelledby="v-pills-settings-tab"
              >
                <div className="row align-items-center">
                  <div className="col-lg-6" style={{ height: "440px" }}>
                    <div
                      className="mgga-features-thumb text-center animated fadeInUp"
                      data-wow-duration="2000ms"
                      data-wow-delay="200ms"
                    >
                      <img
                        src="/video/n2-5.webp"
                        style={{ height: "300px" }}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-6" style={{ height: "440px" }}>
                    <div
                      className="mgga-features-content animated fadeInRight"
                      data-wow-duration="2000ms"
                      data-wow-delay="600ms"
                    >
                      <span
                        style={{
                          fontSize: "25px",
                          fontWeight: 800,
                        }}
                      >
                        Features
                      </span>
                      <h3 className="title">Dual Light ML 100 A</h3>
                      <p style={{ fontSize: "20px" }}>
                        Meticulously designed to enhance precision and clarity.
                        <br />
                        <br />
                        • Dual LED Lights (4 bulbs each)
                        <br />
                        • Optimal brightness and color accuracy.
                        <br />• Different modes based on needs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureNewsletterN2;
