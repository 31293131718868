import { React } from "react";

import { FiArrowRight } from "react-icons/fi";

const callToActionData = {
  title: "Download Our Leaflet ",
  subtitle: "Learn How To Use This Kit For The Following Procedures:",
  subtitle2: "• Crestal Approach Sinus Lift",
  subtitle3: "• Lateral Approach Sinus Lift",
};
const SinusCTA = () => {
  return (
    <div className="callto-action calltoaction-style-default style-4">
      <div className="container">
        <div className="row row--0 align-items-center content-wrapper">
          <div className="col-lg-8">
            <div className="inner">
              <div className="content text-start">
                <h2
                  className="title"
                  style={{ fontSize: "38px", fontWeight: "700" }}
                >
                  {callToActionData.title}
                </h2>
                <h6
                  className="subtitle"
                  style={{ fontSize: "20px", fontWeight: "500" }}
                >
                  {callToActionData.subtitle}
                </h6>
                <br />
                <ul style={{ listStyle: "none" }}>
                  <li
                    className="subtitle mb-2"
                    style={{ fontSize: "18px", fontWeight: "500" }}
                  >
                    {callToActionData.subtitle2}
                  </li>
                  <li
                    className="subtitle mb-2"
                    style={{ fontSize: "18px", fontWeight: "500" }}
                  >
                    {callToActionData.subtitle3}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="button-group" style={{ textAlign: "center" }}>
              <a
                className="btn-default btn-medium  round btn-icon"
                href="/pdf/kit/sinus/tabloid/SinusLeaflet.pdf"
                target="_blank"
                rel="noreferrer"
                download
                style={{ fontSize: "18px", fontWeight: "700" }}
              >
                Download
                <i className="icon">
                  <FiArrowRight />
                </i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SinusCTA;
