import React from "react";

function ItemListings({ data, category, description, bgColor, prodClass }) {
  return (
    <>
      <section
        className={`mgga-team-area pt--70 pb--70`}
        id={prodClass}
        style={{ background: bgColor }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="mgga-section-title text-center">
                <h3 className="mgga-title">{category}</h3>
                <p>{description}</p>
              </div>
            </div>
          </div>
          <div className="row">
            {data.map((item) => (
              <div className="col-lg-3 col-md-6">
                <a href={item.href}>
                  <div
                    className="mgga-team-item mt-30 wow animated fadeInUp"
                    data-wow-duration="2000ms"
                    data-wow-delay="200ms"
                  >
                    <div className="thumb">
                      <img src={item.img} alt={item.alt} />
                      <ul>
                        <li>
                          <a href={item.href} className="">
                            {item.btnName}
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="content text-center">
                      <h5 className="title  mb--0">{item.name}</h5>
                      <span>{item.slogan}</span>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default ItemListings;
