import React from "react";
import FooterOne from "./footer/FooterOne";
import Copyright from "./footer/Copyright";
import HeaderNew from "./header/HeaderNew";
import "./header/Navstyles.css";
import TestHeader from "./header/TestHeader";
import FooterHomeEight from "./footer/FooterTest";

const TestLayout = ({ children }) => {
  return (
    <>
      <main className="page-wrapper">
        <HeaderNew
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-not-transparent"
        />
        {children}
        <FooterHomeEight />
        <Copyright />
      </main>
    </>
  );
};
export default TestLayout;
