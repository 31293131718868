import React, { useState } from "react";
import "../assets/css/style.css";
import Layout from "../common/Layout";
import emailjs from "emailjs-com";
import CTAN2Promo from "../elements/features/CTAN2Promo";
import FeatureNewsletterN2 from "../elements/features/FeaturesNewsletterN2";
import MorePromosN2 from "../elements/features/MorePromosN2";
import GoogleTag from "../elements/pageSections/googletag/GoogleTag";
import { Helmet } from "react-helmet";
import PromoN2Hero from "../elements/pageSections/banner/PromoN2Hero";
import N2HeroSeparator from "../elements/pageSections/banner/N2HeroSeparator";
import ModalPopUp from "../elements/pageSections/modal/Modal";
import DemoHero from "../elements/pageSections/banner/DemoHero";
function DemoPage() {
  const [show, setShow] = useState(true);
  const handleClose = () => {
    setShow(false);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_k12vyev",
        "template_jm9o9v9",
        e.target,
        "Xa6kD888o1i99PsPn"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      )
      .then(e.target.reset())
      .then(window.open("/promo-n2-chair/confirmation"));
  };

  return (
    <>
      <GoogleTag />
      <Helmet>
        <title>N2 Unit Chair Promotion - MegaGen America</title>
      </Helmet>
      <Layout>
        <DemoHero sendEmail={sendEmail} />
      </Layout>
    </>
  );
}

export default DemoPage;
