import FinalCanvas2 from "../../steploader/FinalCanvas2";
import N2PromoForm from "../../form/N2PromoForm";

const PromoN2Hero = ({ className, sendEmail }) => {
  return (
    <section
      className={`mgga-hero-area pt--100 ${className || ""}`}
      style={{ background: "linear-gradient(to bottom, #007BFF, #E0FFFF)" }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="mgga-hero-content">
              <span
                style={{
                  fontSize: "25px",
                  fontWeight: 800,
                  color: "#C0C0C0",
                }}
              >
                The First Class
              </span>
              <h1 className="mgga-title" style={{ color: "white" }}>
                N2 Unit Chair
              </h1>
              <p style={{ color: "white", fontSize: "20px" }}>
                A paradigm of precision, comfort, and innovation tailored for
                your practice. <br />
                Get Up To
                <span
                  style={{
                    fontSize: "30px",
                    fontWeight: 800,
                    color: "#FFD700",
                  }}
                >
                  {" "}
                  50% Off!
                </span>
              </p>
              <N2PromoForm sendEmail={sendEmail} />
            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="mgga-hero-thumb">
              <div
                className="thumb wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <FinalCanvas2 />
              </div>{" "}
              <div>
                <video
                  width="200"
                  height="358"
                  muted
                  autoPlay
                  loop
                  className="thumb-2 wow animated fadeInRight"
                  data-wow-duration="2000ms"
                  data-wow-delay="600ms"
                >
                  <source
                    src="https://mgg-sm-portal.s3.us-east-2.amazonaws.com/n2-7.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PromoN2Hero;
